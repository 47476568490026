import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";
import Footer from "../components/Footer";

const HeaderImage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Honeycomb Blinds | Melbourne | Brisbane | Birk</title>
        <meta
          property="og:title"
          content="Honeycomb Blinds | Melbourne | Brisbane | Birk"
        />
        <meta
          name="keywords"
          content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
        />
        <meta
          name="description"
          content="Birk offers range of quality Honeycomb Blinds. We are operated in Melbourne and Brisbane"
        />
        <meta
          property="og:description"
          content="Birk offers range of quality Honeycomb Blinds. We are operated in Melbourne and Brisbane"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="http://birk.itechnotion.com/honeycomb"
        />
        <link rel="canonical" href="http://birk.itechnotion.com/honeycomb" />
      </Helmet>
      <Header />
      <section className="product-section">
        <div className="container-fluid all-section">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className="heading">
                <h2>HoneyComb</h2>
              </div>
              <div className="product-menu">
                <ul>
                  <li className="mb-2">
                    <Link to="/roller-blinds">Roller Blinds</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/roman-blinds">Roman Blinds</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/curtains">Curtains</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/shutters">Shutters</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/venetians">Venetians</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/external-range">External Range</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/automation">Automation</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/honeycomb">
                      <span className="font-weight-bold">Honeycomb</span>
                    </Link>
                  </li>
                </ul>
                <div className="links-list sidebar-links">
                  <Link to="/gallery" className="links-list-item">
                    <FaMicrosoft />
                    &nbsp; Go to gallery
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-lg-9 product-item mt-2">
              <div className="col-12 col-md-12 mb-4">
                <div className="pro-start-content">
                  <div className="product-inner">
                    <p className="text-justify">
                      Honeycomb blinds are practical with the added benefit of
                      being economical energy savers. The enhanced insulation
                      properties are achieved through the unique hexagonal
                      shaped cells, which hold still air in the pockets. This
                      assists in keeping rooms warmer in winter and cooler in
                      summer.
                    </p>
                    <p className="text-justify">
                      Honeycomb blinds are also extremely durable and available
                      in a large range of colours and textures with various
                      operating options.These blinds come in translucent
                      blockout and sheer materials. Multiple options available.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 col-sm-12 mt-2 mb-4">
                <div className="d-flex flex-wrap">
                  <div className="mb-2 mr-3">
                    <StaticImage
                      src="../images/bhoneycomb-icon-1.png"
                      alt=""
                      className="img-fluid"
                    />
                    <p className="text-center font_style">Single Cell</p>
                  </div>
                  <div className="mb-2 mr-3">
                    <StaticImage
                      src="../images/bhoneycomb-icon-2.png"
                      alt=""
                      className="img-fluid"
                    />
                    <p className="text-center font_style">Double Cell</p>
                  </div>
                  <div className="mb-2 mr-3">
                    <StaticImage
                      src="../images/bhoneybomb-icon3.png"
                      alt=""
                      className="img-fluid"
                    />
                    <p className="text-center font_style">
                      High Insulation
                      <br />
                      Efficiency
                    </p>
                  </div>
                </div>
              </div>
              <div className="why-list why-list2 ml-4">
                <ul>
                  <li>Anti-static fabric aids in resistance of dust</li>
                  <li>
                    Maximises window views & natural light due to minimal stack
                    back
                  </li>
                  <li>Best insulating product; saving money on power bills</li>
                  <li>Excellent light & privacy control</li>
                </ul>
              </div>
              <div className="col-12 col-md-12 mb-4">
                <StaticImage
                  src="../images/gallery/honeycomb/b_honeybomb1.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-12 col-md-12 mb-4">
                <StaticImage
                  src="../images/gallery/honeycomb/b_honeybomb2.png"
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="col-12 col-md-12 mb-4">
                <StaticImage
                  src="../images/gallery/honeycomb/b_honeybomb3.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};
export default HeaderImage;
